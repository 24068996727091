.notification-indicator{
  font-size: 22px;
}

#dropdown-tx-items {
  overflow-y: auto;
  height: 400px;
  margin-left: -160px;
  box-shadow: 4px 4px 6px #d7d7d7, -4px 0px 6px #d7d7d7;
  border-radius: 24px;
  margin-top: 20px;
}

.notification-content {
  // margin-left: -225px;
  // width: 380px;
  // padding: 4px;

  .dropdown-item {
    padding: 4px;
  }
}

.dropdown-content {
  border-radius: 24px!important;
  padding-bottom: 0!important;
  padding-top: 0!important;
  box-shadow: none!important;
}

@media screen and (min-width: 1024px) {
  .navbar {
    min-height: 4rem!important;
  }
}

.dropdown-item.is-flex > div {
  padding: 10px;
}

div.dropdown-item {
  text-align: left;
  white-space: nowrap;
  cursor: pointer;
  text-decoration: none;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
}
div.dropdown-item:hover {
  background-color: whitesmoke;
  border-radius: 24px;
}