.global-blocking-spinner {
  position: fixed;
  z-index: 1000;
  background: white;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  opacity: 0.8;

  img {
    width: 60px;
  }
}