@charset "utf-8";

@import '../../node_modules/bulma/bulma.sass';
@import '../../node_modules/bulma-steps-component/bulma-steps.sass';
@import url('https://fonts.googleapis.com/css2?family=Glory:wght@400&display=swap');
@import './ribbon.scss';

html, body {
  height: 100%;
  font-family: 'Glory', sans-serif;
}

body {
  // background-image: url('../images/white-waves-pattern.png');
  // background-repeat: repeat;
}

.steps {
  .steps-marker-spinner {
    display: none;
  }

  .steps-segment {
    &.is-active {
      &.spin .steps-marker-spinner {
        animation: fa-spin 2s linear infinite;
      }

      .steps-marker {
        color: rgba(0, 0, 0, 0.7) !important;
        background-color: white !important;
        border-width: 0;
      }

      .steps-marker-spinner {
        display: block;
        position: absolute;
        top: 0;
        left: 35px;
        height: 2rem;
        width: 2rem;
        border-color: #5DF5CF;
        border-style: outset;
        border-width: 4px;
        border-radius: 50%;
        z-index: 10;
      }
    }
  }
}

.is-light-red {
  color: #ff4747;
}

.is-light-green {
  color: #5DF5CF;
}

.no-flex-modal-foot {
  display: block;
}


.no-outline:focus, .no-outline:active {
  outline: none!important;
  border: 0;
}

.one-oposite-color-button, .one-oposite-color-button[disabled] {
  background-color: #5DF5CF;
  color: white;
  border: solid 1px #5DF5CF;
}

.one-oposite-color-button-pressed {
  background-color: white!important;
  color: #5DF5CF!important;
  border: solid 1px #d7d7d7;
}

.one-oposite-color-button:hover {
  background-color: white!important;
  color: #5DF5CF;
  border: solid 1px #d7d7d7;
}

.one-color-button, .one-color-button[disabled] {
  background-color: #3A8C9E;
  color: white;
  border: solid 1px #3A8C9E;
}

.one-color-button-pressed {
  background-color: white!important;
  color: #3A8C9E!important;
  border: solid 1px #d7d7d7;
}

.one-color-button:hover {
  background-color: white!important;
  color: #3A8C9E;
  border: solid 1px #d7d7d7;
}